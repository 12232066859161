<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
        <b-link
          class="nav-link"
          @click="$router.push({ name: 'faq'})"
        >
          <feather-icon
            icon="HelpCircleIcon"
            size="21"
          />
        </b-link>
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>
    <div class="bookmark-wrapper align-items-center d-lg-flex">
      <b-alert
        variant="warning"
        class="mb-0"
        show
      >
        <div class="alert-body">
          <span v-if="$store.getters['app/currentBreakPoint'] === 'xs' || $store.getters['app/currentBreakPoint'] === 'sm'"
            v-b-tooltip.hover.bottom="`${$t('Go back to previous version')} ${$t('here')}`"
          >
            <a :href="$store.getters['user/getUserData'].url_sso">
              <feather-icon
                icon="CornerUpLeftIcon"
                size="14"
              />
            </a>
          </span>
          <span v-else>{{$t('Go back to previous version')}} <a :href="$store.getters['user/getUserData'].url_sso">{{$t('here')}}</a> </span>
        </div>
      </b-alert>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale class="nav align-items-center ml-auto"/>
      <dark-Toggler class="d-lg-block" />
      <!-- <search-bar />
      <cart-dropdown /> 
      <notification-dropdown />-->
      <user-dropdown class="nav align-items-center ml-auto" />
    </b-navbar-nav >
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BAlert, VBTooltip
} from 'bootstrap-vue'
import Bookmarks from './Bookmarks.vue'
import Locale from './Locale.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import CartDropdown from '@core/layouts/components/app-navbar/components/CartDropdown.vue'
import NotificationDropdown from './NotificationDropdown.vue'
import UserDropdown from './UserDropdown.vue'
import { decryptData } from '@/auth/utils'

export default {
  components: {
    BLink,
    BAlert, 
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: decryptData(localStorage.getItem('userData')),
    }
  },
}
</script>
