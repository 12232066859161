import { isEducamadridFunction } from '@/main.js';

let routes = [
  {
    header: 'Help',
    resource: 'Help',
    action: 'read',
  },
  {
    title: 'Faq',
    route: 'faq',
    icon: 'LifeBuoyIcon',
    action: 'read',
    resource: 'Help',
  }
];

if (isEducamadridFunction()) {
  routes.push({
    title: 'Videotutorial',
    route: {name:'faq-question', params:{
      category: 'Raices',
      slug:'videotutorial',
      id: 25
    }},
    icon: 'YoutubeIcon',
    action: 'read',
    resource: 'VideotutorialEduca',
  });
}

export default routes;
