
import { getUserRole } from '@/main.js';

let routes = [
    {
        header: 'Area',
        resource: 'Educational Resources',
        action: 'read',
    },
    {
        title: 'Groups',
        route: 'groups',
        icon: 'UsersIcon',
        resource: 'Groups',
        action: 'read',
    },
    {
        title: 'Dreamers',
        route: 'dreamers',
        icon: 'SmileIcon',
        resource: 'Dreamers',
        action: 'read',
    },
    {
        title: 'Learning Paths',
        route: 'learning-paths',
        icon: 'CompassIcon',
        resource: 'Learning Paths',
        action: 'read',
    },
    {
        title: 'Reports',
        route: 'reports',
        icon: 'FileTextIcon',
        resource: 'Reports',
        action: 'read',
    },
    {
        header: 'Educational resources',
        resource: 'Educational Resources',
        action: 'read',
    },
    // {
    //     title: 'Educational Resources',
    //     route: 'resources',
    //     icon: 'ArchiveIcon',
    //     resource: 'Educational Resources',
    //     action: 'read',
    // },
    {
        title: 'Tutorials',
        route: 'tutorials',
        icon: 'VideoIcon',
        resource: 'Educational Resources',
        action: 'read',
    },
    {
        title: 'Guides',
        route: 'guides',
        icon: 'BookmarkIcon',
        resource: 'Educational Resources',
        action: 'read',
    },
    {
        title: 'Catalog',
        route: 'catalogs',
        icon: 'BookOpenIcon',
        resource: 'Educational Resources',
        action: 'read',
    },  
    {
        title: 'Search learning paths',
        route: 'vc-learning-paths',
        icon: 'SearchIcon',
        resource: 'Virtual LP Catalog', 
        action: 'read',
    },
    {
        title: 'Search contents',
        route: 'vc-contents',
        icon: 'SearchIcon',
        resource: 'Virtual Content Catalog', 
        action: 'read',
    },
  ];
  
if (getUserRole() === 'school-admin') {
  routes.push(
    {
      header: 'School Structure',
      resource: 'School Structure',
      action: 'read',
    },
    {
      title: 'Handle',
      route: {
        name: 'school-manage',
        params: { id: 0 }
      },
      icon: 'SettingsIcon',
      resource: 'School Structure',
      action: 'read',
    },
  );
}

export default routes;